import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LangService} from '@app/service/utils';
import { LANG_DEFINITION } from '@app/utils/const';

@Injectable()
export class LanguageESGuard implements CanActivate {

    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const pageLang = route.parent?.paramMap.get('lang');
        if (pageLang === LANG_DEFINITION.es) {
            return true;
        } else {
            this.router.navigate([`${LangService.getLang()}/404`]).then();
            return false;
        }
    }
}

@Injectable()
export class LanguagePTGuard implements CanActivate {

    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {

        const pageLang = route.parent?.paramMap.get('lang');
        if (pageLang === LANG_DEFINITION.pt) {
            return true;
        } else {
            this.router.navigate([`${LangService.getLang()}/404`]).then();
            return false;
        }
    }
}

