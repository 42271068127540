import { Routes } from '@angular/router';
import { FullLayoutComponent } from './components/layouts/full-layout/full.layout.component';
import { SimpleLayoutComponent } from './components/layouts/simple-layout/simple.layout.component';
import { LoginGuard } from './guards/login.guard';
import { AccountComponent } from './components/private/account';
import { NotFoundComponent } from './components/public/notfound';
import { DeleteAccountEmailComponent } from './components/private/delete-account-email/delete-account-email.component';
import { PublicFormsLayoutComponent } from './components/layouts/public-forms-layout/forms.layout.component';
import { PUBLIC_FORMS_ROUTES } from './components/public-forms/routes';
import { PublicLayoutComponent } from './components/layouts/public-layout/public.layout.component';



export const routes: Routes = [
    {
        path: ':lang/forms',
        component: PublicFormsLayoutComponent,
        children: PUBLIC_FORMS_ROUTES
    },
    {
        path: '',
        redirectTo: 'es/login',
        pathMatch: 'full',
    },
    {
        path: 'es',
        redirectTo: 'es/login',
        pathMatch: 'full',
    },
    {
        path: 'it',
        redirectTo: 'it/login',
        pathMatch: 'full',
    },
    {
        path: 'pt',
        redirectTo: 'pt/login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: PublicLayoutComponent,
        loadChildren: () => import('./components/public/public.module').then(m => m.PublicModule)
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: ':lang/purchases',
                loadChildren: () => import('./components/private/insurances/list-insurance/insurnaces.module').then(m => m.InsuranceModule)
            },
            {
                path: ':lang/claims',
                loadChildren: () => import('./components/private/claims/list/claims.module').then(m => m.ClaimsModule)
            },
            {
                path: ':lang/help',
                loadChildren: () => import('./components/private/help/help.module').then(m => m.HelpModule)
            },
            {
                path: ':lang/account',
                loadChildren: () => import('./components/private/account/account.module').then(m => m.AccountModule)
            },
            {
                path: ':lang/password/edit',
                component: AccountComponent,
                canActivate: [LoginGuard]
            }
        ]
    },
    {
        path: '',
        component: SimpleLayoutComponent,
        children:[
            {
                path: ':lang/addPolicy',
                loadChildren: () => import('./components/private/insurances/add-insurance/add-insurance.module').then(m => m.NewInsuranceModule)
            },
            {
                path: ':lang/new-claim',
                loadChildren: () => import('./components/private/claims/new-claim/new-claim.module').then(m => m.NewClaimModule)
            },
            {
                path: ':lang/delete-account/email-sent',
                component: DeleteAccountEmailComponent,
                canActivate: [LoginGuard]
            }
        ]
    },
    {
        path: '**',
        redirectTo: '404'
    }
];


