import { Routes } from '@angular/router'
import { LanguageESGuard, LanguagePTGuard } from 'src/app/guards/language.guard'

export const PUBLIC_FORMS_ROUTES: Routes = [
    {
        path: 'veci',
        redirectTo: 'veci/new-claim',
        pathMatch: 'full',
    },
    {
        path: 'veci/new-claim',
        loadComponent: () => import('./veci/veci-container.component').then(c => c.VeciContainerComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: 'veci/:id/success',
        loadComponent: () => import('./veci/sucess/success.veci.component').then(c => c.VeciSuccessMessageComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: 'servisegur',
        redirectTo: 'servisegur/new-claim',
        pathMatch: 'full',
    },
    {
        path: 'servisegur/new-claim',
        loadComponent: () => import('./servisegur/servisegur-container.component').then(c => c.ServisegurContainerComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: 'servisegur/:id/success',
        loadComponent: () => import('./servisegur/success/succes.servisegur.component').then(c => c.ServisegurSuccessMessageComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: 'travelconcept',
        redirectTo: 'travelconcept/new-claim',
        pathMatch: 'full',
    },
    {
        path: "travelconcept/new-claim",
        loadComponent: () => import('./travelconcept/travelconcept-container.component').then(c => c.TravelconceptContainerComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: "travelconcept/:id/success",
        loadComponent: () => import('./travelconcept/success/success.travelconcept.component').then(c => c.TravelconceptSuccessMessageComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: "travelconcept/novo-sinistro",
        loadComponent: () => import('./travelconcept-pt/travelconcept-pt-container.component').then(c => c.TravelconceptPtContainerComponent),
        canActivate: [LanguagePTGuard]
    },
    {
        path: "travelconcept/:id/sucesso",
        loadComponent: () => import('./travelconcept-pt/success/success.travelconcept-pt.component').then(c => c.TravelconceptPtSuccessMessageComponent),
        canActivate: [LanguagePTGuard]
    },
    {
        path: 'intertech',
        redirectTo: 'intertech/new-claim',
        pathMatch: 'full',
    },
    {
        path: 'intertech/new-claim',
        loadComponent: () => import('./intertech/intertech-container.component').then(c => c.IntertechContainerComponent),
        canActivate: [LanguageESGuard]
    },
    {
        path: 'intertech/:id/success',
        loadComponent: () => import('./intertech/success/success.intertech.component').then(c => c.IntertechSuccessMessageComponent),
        canActivate: [LanguageESGuard]
    }
]