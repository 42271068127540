import { Component, OnInit} from "@angular/core";
import { ChatService } from "@app/service/utils/chat.service";
import { LangService } from "@app/service/utils/lang.service";
import { LANG_DEFINITION } from "@app/utils/const";
@Component({
    selector: 'app-public-layout',
    template: `
        <div>
            <router-outlet></router-outlet>
        </div>
    `
})
export class PublicLayoutComponent implements OnInit  {
    constructor(
        private chatService: ChatService
    ){}

    ngOnInit(): void {
        // dependiendo del idioma se muestra o no el chat
        if(LangService.getLang() === LANG_DEFINITION.es){
            console.log('show chat');
            this.chatService.show();
        }else{
            console.log('hide chat');
            this.chatService.hide();
        }
    }
}