import { NgModule } from "@angular/core";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";
import { TranslateModule } from "@ngx-translate/core"
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BottombarComponent } from "./bottombar/bottombar.component";
import { RouterModule} from "@angular/router";
import { FullLayoutComponent } from "./full-layout/full.layout.component";
import { SimpleLayoutComponent } from "./simple-layout/simple.layout.component";
import { CommonModule } from "@angular/common";
import { LogoLinkComponent } from "../utils/logo-link/index";
import { PublicFormsLayoutComponent } from "./public-forms-layout/forms.layout.component";
import { ButtonPrimary } from "@intm-ui/buttons";
import { PublicLayoutComponent } from "./public-layout/public.layout.component";

@NgModule({
    declarations:[
        SidebarComponent,
        BottombarComponent,
        FullLayoutComponent,
        SimpleLayoutComponent,
        PublicFormsLayoutComponent,
        PublicLayoutComponent
    ],
    imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TypographicTokensModule,
    LogoLinkComponent,
    ButtonPrimary
],
    exports:[SimpleLayoutComponent, FullLayoutComponent, PublicLayoutComponent]
})
export class LayoutModule {}